<template>
  <div class="EsignConsentEditor">

    <div class="esign-top-options">
      <input
        type="text"
        class="ui-native"
        v-model="innerModel.documentName"
        @input="emitInput"
        placeholder="Nombre del documento"
        size="60"
      />
      <input
        type="text"
        class="ui-native"
        v-model="innerModel.personId"
        @input="emitInput"
        placeholder="ID de estudiante"
      />

      <div class="EsignConsentEditor__signers">
        <label>Firmantes:</label>

        <span>
          <input
            type="radio"
            id="signers-all"
            value="all"
            v-model="signers"
          >
          <label for="signers-all">Todos</label>
        </span>

        <span>
          <input
            type="radio"
            id="signers-author"
            value="author"
            v-model="signers"
          >
          <label for="signers-author">Autor</label>
        </span>

        <span>
          <input
            type="radio"
            id="signers-none"
            value="none"
            v-model="signers"
          >
          <label for="signers-none">No requiere firma</label>
        </span>
      </div>
    </div>

    <div class="pdf-container">
      <div class="document-html pdf-html">
        <HtmlEditor
          v-model="innerModel.source.html"
          @input="emitInput"
        />
      </div>
    </div>

    <div class="esign-consent-toolbar">
      <label>
        <input
          type="checkbox"
          v-model="showSource"
        /> Ver HTML
      </label>
    </div>

    <div
      class="source-tools"
      v-if="showSource"
    >
      <textarea
        v-model="innerModel.source.html"
        @input="emitInput"
        style="display: block; width: 100%; min-height: 200px"
      ></textarea>

      <UiInputJson
        v-model="innerModel.source.options"
        @input="emitInput"
      />

      <div class="pdf-preview">
        <HtmlToPdf
          :html="innerModel.source.html"
          :options="innerModel.source.options"
        />
      </div>
    </div>

  </div>
</template>

<script>
import HtmlEditor from '@/modules/cms/components/Media/Html/HtmlEditor.vue';
import HtmlToPdf from '../HtmlToPdf/HtmlToPdf.vue';
import { UiInputJson } from '@/modules/ui/components';

export default {
  name: 'EsignConsentEditor',
  components: { HtmlToPdf, UiInputJson, HtmlEditor },

  props: {
    value: {
      required: false,
      default: null,
    },
  },

  data() {
    return {
      innerModel: null,
      showSource: false,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.innerModel = newValue ? JSON.parse(JSON.stringify(newValue)) : {};
        if (
          !this.innerModel.source ||
          typeof this.innerModel.source != 'object' ||
          !this.innerModel.source.html
        ) {
          this.innerModel.source = {
            html: '',
            options: {
              orientation: 'P',
            },
          };
        }
      },
    },
  },

  methods: {
    emitInput() {
      this.$emit('input', JSON.parse(JSON.stringify(this.innerModel)));
    },
  },

  computed: {
    signers: {
      // returns 'none', 'author', 'all'
      get() {
        if (this.innerModel.ignoreSignatures) {
          return 'none';
        }

        return this.innerModel.authorSignatureOnly ? 'author' : 'all';
      },

      // 'none', 'author', 'all'
      set(newValue) {
        this.innerModel.ignoreSignatures = newValue == 'none';
        this.innerModel.authorSignatureOnly = newValue == 'author';
        this.emitInput();
      },
    },
  },
};
</script>

<style lang="scss">
.EsignConsentEditor {
  .pdf-container {
    padding: 24px;
    background-color: rgba(0, 0, 0, 0.07);
  }

  .pdf-html {
    margin: auto;
    max-width: 800px;
    font-family: 'Times New Roman', Times, serif !important;
    padding: 36px 36px;
    background-color: #fff;
    box-shadow: 0px 2px 12px 0px #00000042;

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: 'Times New Roman', Times, serif !important;
      font-weight: bold;
    }
  }

  .esign-consent-toolbar {
    margin: var(--ui-breathe) 0;
  }

  &__signers {
    padding: 0.5rem;

    & > span {
      margin-right: 1em;
      display: inline-flex;
      align-items: center;

      input {
        margin: 0 0.3rem;
      }
    }
  }
}
</style>